import { FiMail } from "react-icons/fi";
import { IoLocationSharp } from "react-icons/io5";

import aboutImg from '../assets/home/about.jpg'
import servicosImg from '../assets/home/servicos.jpg'
import networkingImg from '../assets/home/networking.jpg'
import contactsImg from '../assets/home/contacts.jpg'

import { Section } from "./content-PT";

export const contentENG: Section[] = [
    {
        id: "about",
        title: "About",
        texts: [
            {
                text: "Security must be approached from a holistic point of view: the perimeter that existed in traditional architectures has faded. The new paradigm requires a focus on users and data. ",
                strong: false,
                icon: false
            },
            {
                text: "As a result of a new reality that has invaded us, mobility today is an inseparable factor of the mundane habits of IT use and its devices, whose security remains a challenge and must necessarily be addressed with due propriety. ",
                strong: false,
                icon: false
            },
            {
                text: "The expansion of networks to a new multitude of non-traditional devices, IoT, presents a new paradigm, requiring an approach with greater flexibility and versatility than what is done for traditional networks. ",
                strong: false,
                icon: false
            },
            {
                text: "We have a highly qualified team, capable of assessing the maturity state of your company and advising a transformation plan adapted to your needs, which can range from optimizing current infrastructures to adding surgical components that contribute to its resilience and security.",
                strong: false,
                icon: false
            },
        ],
        btn: {
            link: "",
            text: "Texto"
        },
        img: networkingImg,
    },
    {
        id: "services",
        title: "Service Offerings ",
        texts: [
            {
                strong: false,
                icon: false,
                text: "All solutions are designed to maximize their benefits by leveraging advanced technologies, in partnership with leading manufacturers worldwide."
            },
            {
                text: "Cybersecurity Consulting Services ",
                strong: true,
                icon: false
            },
            {
                strong: false,
                icon: false,
                text: "Services focused on the importance of security for people and data in the access and provision of IT services. "
            },
            {
                strong: true,
                icon: false,
                text: "Visibility of the Security State of Organizations ",
            },
            {
                strong: false,
                icon: false,
                text: "Assessments and audits aimed at analyzing the security state and exposure of organizations, in terms of internal and external vulnerabilities, redundancy, and resilience, with the goal of preparation for various scenarios, such as contingencies or business continuity. ",
            },
            {
                text: "Design, Implementation, and Support of Cybersecurity Solutions ",
                strong: true,
                icon: false,
            },
            {
                text: "Design, implementation, and support of “secured by design” solutions tailored to the client, suitable for each case. ",
                strong: false,
                icon: false,
            },
            {
                text: "End-User Awareness and Training",
                strong: true,
                icon: false,
            },
            {
                text: "Training and awareness actions for end-users, based on the themes and risks inherent in information technologies.",
                strong: false,
                icon: false,
            },
        ], btn: {
            link: "/#contacts",
            text: "Contact us!"
        },
        img: servicosImg,
    },
    {
        id: "networking",
        title: "Networking Offer",
        texts: [
            {
                text: "Networking Consulting Services ",
                strong: true
            },
            {
                text: "Services focused on aspects of LAN, WAN, and Wi-Fi networks. ",
                strong: false
            },
            {
                text: "Visibility of the State of Organizations' Networks",
                strong: true
            },
            {
                text: "Assessments and audits aimed at analyzing the security state and exposure of organizations regarding their LAN, WAN, and Wi-Fi networks, in terms of redundancy and resilience, with the goal of preparation for various scenarios, such as contingencies or business continuity. ",
                strong: false
            },
            {
                text: "Design, Implementation, and Support of LAN, WAN, and Wi-Fi Networks ",
                strong: true
            },
            {
                text: "Design, implementation, and support of LAN, WAN, and Wi-Fi network solutions, including conducting “site surveys”.",
                strong: false
            },
        ],
        btn: {
            link: "",
            text: ""
        },
        img: contactsImg,
    },
    {
        id: "contacts",
        title: "Contacts",
        texts: [
            {
                text: "info@safevanguard.com",
                link: 'mailto:info@safevanguard.com',
                icon: <FiMail />,
                strong: false
            },
            {
                text: "Campo Grande 28, 4ºB 1700-093 Lisboa Portugal",
                strong: false,
                icon: <IoLocationSharp />,
                link: "https://www.google.pt/maps/place/Campo+Grande+28,+1700-053+Lisboa/@38.7496476,-9.1502989,17z/data=!3m1!4b1!4m5!3m4!1s0xd193300da08ac9d:0xcc4ca529ccd9ae7!8m2!3d38.7496476!4d-9.1481102?shorturl=1"

            },
        ],
        btn: {
            link: "",
            text: ""
        },
        img: aboutImg,
    }
]