import { FiMail } from "react-icons/fi";
import { IoLocationSharp } from "react-icons/io5";

import aboutImg from '../assets/home/about.jpg'
import servicosImg from '../assets/home/servicos.jpg'
import networkingImg from '../assets/home/networking.jpg'
import contactsImg from '../assets/home/contacts.jpg'

interface TextItem {
    text: string;
    strong?: boolean;
    icon?: boolean | JSX.Element;
    link?: string;
}

interface Button {
    link: string;
    text: string;
}

export interface Section {
    id: string;
    title: string;
    texts: TextItem[];
    btn: Button;
    img: string;
}

export const contentPT: Section[] = [
    {
        id: "about",
        title: "Sobre",
        texts: [
            {
                text: "A segurança tem de ser encarada de um ponto de vista holístico: o perímetro que existia nas arquiteturas tradicionais desvaneceu-se. O seu novo paradigma obriga a que o enfoque se centre nos utilizadores e nos dados.",
                strong: false,
                icon: false
            },
            {
                text: "Fruto de uma nova realidade que nos invadiu, a mobilidade é hoje um fator indissociável dos hábitos banais de utilização das TIs e dos seus dispositivos, cuja segurança continua a ser ainda um desafio e tem forçosamente de ser endereçado com a devida propriedade.",
                strong: false,
                icon: false
            },
            {
                text: "A abrangência das redes a uma nova multitude de dispositivos não tradicionais, IoT, apresenta um novo paradigma, que requer uma abordagem com uma maior flexibilidade e versatilidade do que é realizado para as redes tradicionais.",
                strong: false,
                icon: false
            },
            {
                text: "Contamos com uma equipa altamente qualificada, capaz de avaliar o estado de maturidade da sua empresa e de aconselhar um plano de transformação adaptado às suas necessidades, que podem ir desde a otimização das infraestruturas atuais à adição de componentes cirúrgicos que contribuam para a sua resiliência e segurança.",
                strong: false,
                icon: false
            },
        ],
        btn: {
            link: "",
            text: "Texto"
        },
        img: networkingImg,
    },
    {
        id: "services",
        title: "Oferta de Serviços",
        texts: [
            {
                strong: false,
                icon: false,
                text: "Todas as soluções são desenhadas de modo a maximizar os seus benefícios tirando partido de tecnologias avançadas, em parceria com os principais fabricantes a nível mundial."
            },
            {
                text: "Serviços de Consultoria para Cibersegurança",
                strong: true,
                icon: false
            },
            {
                strong: false,
                icon: false,
                text: "Serviços focados na importância da segurança das pessoas e dados no acesso e disponibilização de serviços de TI."
            },
            {
                strong: true,
                icon: false,
                text: "Visibilidade do Estado da Segurança das Organizações",
            },
            {
                strong: false,
                icon: false,
                text: "Assessments e auditorias com vista à análise do estado de segurança e exposição das organizações, em termos de vulnerabilidades internas e externas, redundância e resiliência, com o objectivo de preparação para cenários diversos, como contigências ou continuidade de negócio.",
            },
            {
                text: "Desenho, Implementação e Suporte de Soluções de Cibersegurança",
                strong: true,
                icon: false,
            },
            {
                text: "Desenho, implementação e suporte de Soluções “secured by design” à medida do cliente, adequadas a cada caso. ",
                strong: false,
                icon: false,
            },
            {
                text: "End-User Awareness and Training",
                strong: true,
                icon: false,
            },
            {
                text: "Ações de formação e sensibilização para utilizadores finais, assentes na temática e riscos inerentes às tecnologias de informação.",
                strong: false,
                icon: false,
            },
        ], btn: {
            link: "",
            text: ""
        },
        img: servicosImg,
    },
    {
        id: "networking",
        title: "Oferta Networking",
        texts: [
            {
                text: "Serviços de Consultoria para Networking",
                strong: true
            },
            {
                text: "Serviços de Consultoria para Networking",
                strong: false
            },
            {
                text: "Visibilidade do Estado das Redes das Organizações",
                strong: true
            },
            {
                text: "Assessments e auditorias com vista à análise do estado de segurança e exposição das organizações, no que diz respeito às suas redes LAN, WAN e Wi-Fi, em termos de redundância e resiliência, com o objetivo de preparação para cenários diversos, como contingências ou continuidade de negócio.",
                strong: false
            },
            {
                text: "Desenho, Implementação e Suporte de Redes LAN, WAN e Wi-Fi",
                strong: true
            },
            {
                text: "Desenho, implementação e suporte de Soluções de redes LAN, WAN e Wi-Fi, incluindo a realização de “site surveys”. ",
                strong: false
            },
        ],
        btn: {
            link: "",
            text: ""
        },
        img: contactsImg,
    },
    {
        id: "contacts",
        title: "Contactos",
        texts: [
            {
                text: "info@safevanguard.com",
                link: 'mailto:info@safevanguard.com',
                icon: <FiMail />,
                strong: false
            },
            {
                text: "Campo Grande 28, 4ºB 1700-093 Lisboa Portugal",
                strong: false,
                icon: <IoLocationSharp />,
                link: "https://www.google.pt/maps/place/Campo+Grande+28,+1700-053+Lisboa/@38.7496476,-9.1502989,17z/data=!3m1!4b1!4m5!3m4!1s0xd193300da08ac9d:0xcc4ca529ccd9ae7!8m2!3d38.7496476!4d-9.1481102?shorturl=1"

            },
        ],
        btn: {
            link: "",
            text: ""
        },
        img: aboutImg,
    }
]