import logoHor from '../assets/logo-hor.png'
import useLanguage from '../hook/useLanguage';

export function Footer() {
    const { language} = useLanguage();

    const sytles = {
        link: 'text-base hover:text-costumRed hover:font-bold transition-all duration-700'
    }

    return (
        <footer className="xs:grid-row-3 grid md:grid-cols-3 px-[5%] gap-5 py-12 items-start justify-between w-full bg-white shadow-[0_35px_50px_10px_rgba(0,0,0,0.3)] mt-5">
            <img src={logoHor} className='max-w-[50%]' alt="logo" />
            <div className="flex flex-col gap-3 items-start">
                {language.map((data, index) => (
                    <a className={sytles.link} key={index} href={`/#${data.id}`}>{data.title}</a>
                ))}
            </div>
            <div className="flex flex-col items-start gap-3">
                <a className={sytles.link} href="https://www.google.pt/maps/place/Campo+Grande+28,+1700-053+Lisboa/@38.7496476,-9.1502989,17z/data=!3m1!4b1!4m5!3m4!1s0xd193300da08ac9d:0xcc4ca529ccd9ae7!8m2!3d38.7496476!4d-9.1481102?shorturl=1">Campo Grande 28 4ºB, 1700-093 Lisboa</a>
                <a className={sytles.link} href="mailto:info@safevanguard.pt">info@safevanguard.pt</a>
            </div>

        </footer>
    );
}

