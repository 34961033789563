import { HiOutlineChevronRight } from "react-icons/hi";
import heroHome from '../assets/bg-home.jpg'
import { NavBar } from '../components/navBar';
import { Footer } from '../components/footer';
import './homeStyle.css'
import useLanguage from "../hook/useLanguage";

export function Home() {

  const { language, isPT } = useLanguage();

  const gridItems = language.map((data, index) => (
    <section className="homeSection" key={index} id={data.id}>
      <div id="about">
        <h1>{data.title}</h1>
        {data.texts.map((text, index) => (
          <>
            {text.link ? <a href={text.link} key={index} className={`${text.strong ? "font-bold" : null}`}>{text.icon}{text.text}</a> :
              <p key={index} className={`${text.strong ? "font-bold" : null}`}>{text.icon}{text.text}</p>}
          </>
        ))}

        {data.btn.link ? <a href={data.btn.link}>{data.btn.text} <HiOutlineChevronRight /></a> : null}
      </div>
      <img src={data.img} alt="" />
    </section>
  ));

  return (
    <div className="w-[100vw] h-auto relative flex flex-col overflow-y-auto">
      <NavBar />
      <section className='w-full h-[100vh] relative overflow-hidden'>
        <section className='text-white absolute z-20 top-1/2 transform -translate-y-1/2 w-full px-[5%] '>
          <h1 className="md:text-[70px] font-bold">Safe Vanguard</h1>
          <h2 className="md:text-[50px] font-light">{isPT ? "A sua segurança, a nossa prioridade" : "Your security, our priority"}</h2>
        </section>
        <svg className='h-[80%] w-auto absolute right-0 translate-x-[35%] z-10 top-1/2 transform -translate-y-1/2' width="127" height="144" viewBox="0 0 127 144" fill="none" xmlns="http://www.w3.org/2000/svg ">
          <path d="M63.3704 0.191467C84.2847 12.4875 105.199 24.7836 126.113 37.0825V110.299C106.112 121.468 86.1143 132.64 66.1134 143.808V43.1009C73.0189 49.3465 79.9244 55.5892 86.827 61.8348V113.341C88.3134 112.511 89.7998 111.68 91.2891 110.85C91.2891 95.8565 91.2891 80.863 91.2891 65.8694C95.7744 69.9244 100.26 73.9823 104.745 78.0373V103.337C106.214 102.516 107.683 101.694 109.155 100.876V46.9462C93.8954 37.9739 78.6329 29.0017 63.3733 20.0294V0.191467H63.3704Z" fill="#963435" />
          <path d="M63.3706 0.191467C42.4563 12.4875 21.542 24.7836 0.627686 37.0825V110.299C20.6286 121.468 40.6267 132.64 60.6276 143.808C60.6276 110.238 60.6276 76.6682 60.6276 43.098C53.7221 49.3436 46.8166 55.5863 39.914 61.8319C39.914 79.0015 39.914 96.1682 39.914 113.338C38.4276 112.508 36.9412 111.677 35.4519 110.847C35.4519 95.8536 35.4519 80.8601 35.4519 65.8665C30.9665 69.9215 26.4811 73.9794 21.9958 78.0344C21.9958 86.4677 21.9958 94.901 21.9958 103.334C20.5268 102.513 19.0579 101.691 17.586 100.873C17.586 82.8963 17.586 64.9198 17.586 46.9432C32.8456 37.971 48.1081 28.9988 63.3677 20.0265C63.3677 13.4168 63.3677 6.80704 63.3677 0.19438L63.3706 0.191467Z" fill="#BB3D33" />
        </svg>
        <img className='w-full h-full absolute top-0 left-0 z-0 brightness-[50%]' src={heroHome} alt="" />
      </section>
      <div className="flex flex-col ">
        {gridItems}
      </div>
      <Footer />
    </div>
  );
}
