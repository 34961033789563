import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Home } from './pages/home';
import reportWebVitals from './reportWebVitals';
import { LanguageProvider } from './context/languageContext';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <LanguageProvider>
      <Home />
    </LanguageProvider>
  </React.StrictMode>
);

reportWebVitals();
