import { BiMenu } from "react-icons/bi";
import logoHor from '../assets/logo-hor.png'
import './navBarStyle.css'
import { useState } from "react";
import useLanguage from "../hook/useLanguage";
export function NavBar() {

  const pageWidth = window.innerWidth;
  const [active, setActive] = useState<boolean>();

  const { changeLanguage, language} = useLanguage();
  return (
    <nav className={`navBar ${active ? "active" : null}`}>
      <div className='images'>
        <a className='logo' href="/"><img src={logoHor} alt="logo" /></a>
        {pageWidth <= 1200 ? <BiMenu className="menu" onClick={() => setActive(!active)} /> : null}
      </div>
      <div className='links'>
        {language.map((data, index) => (
          <a className='text-lg font-medium' key={index} onClick={() => setActive(false)} href={`/#${data.id}`}>{data.title}</a>
        ))}
      </div>
      <div className="language">
        <label className="toggle">
          <input type="checkbox" id="toggleSwitch" onClick={() => {changeLanguage()}}/>
            <span className="slider round"></span>
        </label>
      </div>
    </nav>
  );
}

