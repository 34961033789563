import React, { createContext, useState, ReactNode } from 'react';
import { contentPT } from "../data/content-PT";
import { contentENG } from "../data/content-ENG";
import { Section } from "../data/content-PT";

interface LanguageContextProps {
    isPT: boolean;
    changeLanguage: () => void;
    language: Section[]
}

interface LanguageProviderProps {
    children: ReactNode;
}

export const LanguageContext = createContext<LanguageContextProps>({
    isPT: false,
    changeLanguage: () => {},
    language: []
});

export function LanguageProvider({ children }: LanguageProviderProps) {
    const [isPT, setIsPT] = useState<boolean>(true);
    const language = isPT ? contentPT : contentENG;

    function changeLanguage() {
        setIsPT(prevState => !prevState);
    }

    return (
        <LanguageContext.Provider value={{
            isPT,
            changeLanguage,
            language,
        }}>
            {children}
        </LanguageContext.Provider>
    );
}
